.discussProjectBtn {  
    margin-left: 1rem;
    position: absolute;
    bottom: 10%;
    z-index: 1;
    border-radius: 0.4rem;
    &::after {
        animation: 1.3s blinking linear infinite;
        z-index: -1;
        border-radius: 50%;
        -webkit-border-radius: 0.4rem;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    & a {
        font-size: 1.4rem;
    }
}


// sign in page animated text cursor style 
.typed-cursor{
	display:none
  }

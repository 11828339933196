//ViewOrdersrders.js topMenuMobileTotalDiv
.topMenuMobileTotalDiv{
	opacity: 0;
	max-height: 0;
	// transition: max-height 0.25s ease-out;
	margin-bottom: 0 !important;
	
}

.topMenuMobileTotalDivactive{
	max-height: 30rem;
	opacity: 1;
	transition: max-height 0.25s ease-in;
	& > *{
		margin-bottom: 2rem !important;
	}
	
}
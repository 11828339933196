// Successful 	Registered Card
#card {
	position: relative;
	width: 320px;
	display: block;
	margin: 40px auto;
	text-align: center;
	font-family: 'Source Sans Pro', sans-serif;
  }
  
  #upper-side {
	padding: 2em;
	background-color: $color-primary;
	display: block;
	color: #fff;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	& svg{
		font-size: 3rem;
	}
  }
  
  #checkmark {
	font-weight: lighter;
	fill: #fff;
	margin: -3.5em auto auto 20px;
  }
  
  #status {
	font-weight: lighter;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 2.6rem;
	margin-top: -.2em;
	margin-bottom: 0;
  }
  
  #lower-side {
	padding: 2em 2em 5em 2em;
	background: #fff;
	display: block;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
  }
  
  #message {
	margin-top: -.5em;
	color: #757575;
	letter-spacing: 1px;
	font-size: 1.6rem;
  }
  
  #contBtn {
	position: relative;
	top: 1.5em;
	text-decoration: none;
	background: $color-primary;
	color: #fff;
	margin: auto;
	font-size: 1.6rem;
	padding: .8em 3em;
	-webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
	-moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
	box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
	border-radius: 25px;
	-webkit-transition: all .4s ease;
		  -moz-transition: all .4s ease;
		  -o-transition: all .4s ease;
		  transition: all .4s ease;
  }
  
  #contBtn:hover {
	-webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
	-moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
	box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
	-webkit-transition: all .4s ease;
		  -moz-transition: all .4s ease;
		  -o-transition: all .4s ease;
		  transition: all .4s ease;
  }
.btn {
    z-index: 4;
   
    &,
    &:link,
    &:visited {
        width: 100%;
    text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 300;
      padding: 1.5rem 1.2rem;
      display: inline-block;
      border-radius: 0.4rem;
      transition: all 0.2s;
      position: relative;
  
      color: $color-white;
  
      //Change for the <button> element
      border: none;
      cursor: pointer;
    }
  
    &-small {
      padding: 1rem 1rem;
      font-size: 1;
    }
    &-medium {
      padding: 1.5rem 1.2rem;
      font-size: $default-font-size;
    }
  
    &--white {
      background-color: $color-white;
      color: $color-grey-dark;
  
      &::after {
        background-color: $color-white;
      }
    }
  
    &--green {
      background-color: $color-primary;
      color: $color-white;
  
      &::after {
        background-color: $color-primary;
      }
    }
  }
  
  .btn-text {
    &:link,
    &:visited {
      font-size: $default-font-size;
      color: $color-primary;
      display: inline-block;
      text-decoration: none;
      border-bottom: 1px solid $color-primary;
      padding: 3px;
      transition: all 0.2s;
    }
  
    &:hover {
      background-color: $color-primary;
      color: $color-white;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
      transform: translateY(-2px);
    }
  
    &:active {
      box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
      transform: translateY(0);
    }
  }
  
 
  .btn-green {
    background-color: $color-primary;
  }
  .site_btn {
    background: linear-gradient(
      to right,
      rgb(91,43,23) 0%,
      rgb(128, 60, 31) 100%
    );
    &:hover {
      background: linear-gradient(
        to right,
        rgb(128, 60, 31) 0%,
        rgb(128, 60, 31) 100%
      );
    }
  }
  
  .point-blink {
    position: relative;
    height: 15px;
    width: 15px;
    background: transparent;
    border-radius: 50px;
    border: 3px solid #fff;
    display: inline-block;
    z-index: 1;
  
    top: 1px;
    &::after {
      animation: 1.3s blinking2 linear infinite;
      z-index: -1;
      border-radius: 50%;
      -webkit-border-radius: 4px;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }


  

@media screen {
  


    .invoice-box{
        height: max-content;
        width: 100%;
    }
    .details-div{
        width: 100%;
        margin-bottom:2rem
    }
    .heading-div{
      min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-width: 100%;
        // border-bottom: 1rem solid $color-primary;
        @include respond(phone){
          min-width: 73rem;
        }
    }
    .Main-Heading{
    font-weight: bolder;
    color: $color-primary;
    font-size: 4.6rem;
    width: 50%;
    float: left;
    // @include respond(phone){
    //   font-size: 2.6rem;	
    // }
    }
    .sub-Heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    float: right;
    position: relative;
    }
    h6{
      font-size: 1.6rem;
      font-weight: bolder;
    }
    
    .table-container {
	
	max-width: 100%;
	margin: 0 auto 0;
	// padding-left: 1rem;
	position:relative;
	overflow-y:auto;
	&__print_table {
	  height: min-content;
	  width: 100%;
	  border-collapse: collapse;
	  tbody{
		tr {
			&:nth-child(odd) {

				
        & td {
          background-color: $bg-color;
        } 
		}
		  }
	  }
	  thead {
  
		tr {
			
			background-color: $color-primary-light ;
		  th{
			color:$color-white;
			// font-weight: 900;
			
		  }
		}
	  }
  
	  td, th {
		border: none;
		padding: .2rem ;
		padding-left: .5rem;
		font-size: 1.3rem;
		border: .1rem solid $color-primary;
		
		text-align: left;
	  }

	  }
  
	//   &--break-sm {
  
	// 	@media (max-width: 575px) {
	// 	  @include break;
	// 	}
	//   }
	
  }
    

    
  }



// print view styles for Customer list

@media print{
//   /* these styles only work on Customer_List print View */

// /* specfic page selection on print view */
// @page{
//   size: A4 landscape !important;
// }

//   body::not(.invoice-box){
//   display: none;
// }
// /* customer_List - main div */
// #invoice-box{
//   height: max-content;
//   min-width:  max-content;
// }
// /* customer_List - header div */
//  #heading-div{
//   min-width: 1250px !important;
//  }
//  /* customer_List - Mainheading */
 
  
//   /* customer_List - subheading */
// #Sub-Heading{
//      min-width: max-content !important;
//      float: right;
//   }
// #Customer_heading_h6,#Customer_date_h6{
//   font-size: 22px;
// }
// /* customer_List -table */
// table{
//   border-collapse: collapse;
// }
// /* customer_List - table rows and column  */
// td, th {
//   padding: 5px;
//   border: 1px solid brown;
//   text-align: left;

// }


// #address , #name{
//   min-width: 300px !important
// }


}
















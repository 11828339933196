
@keyframes blinking {
    0% {
        box-shadow: 0 0 0 5px  rgb(91,43,23);
        -webkit-box-shadow: 0 0 0 5px  rgb(128, 60, 31) ;
        opacity: 1;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }

    50% {
       box-shadow: 0 0 0 5px  rgba(91,43,23,.6);
        -webkit-box-shadow: 0 0 0 5px  rgba(128, 60, 31,.6);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(140, 199, 73, 0);
        -webkit-box-shadow: 0 0 0 15px rgba(140, 199, 73, 0);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }
}

@keyframes blinking2 {
    0% {
        box-shadow: 0 0 0 5px rgb(255, 255, 255);
        -webkit-box-shadow: 0 0 0 5px rgb(255, 255, 255);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }

    50% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
        -webkit-box-shadow: 0 0 0 10px rgba(253, 253, 253, 0.6);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
        -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, .5);
        transform: scale3d(1, 1, .5)
    }
}

@media only screen and (min-width: 999px) {
    #table-stats {
        width: 29%;
    }

    #table-main-small {
        display: none
    }
}

#table-main {
    text-align: center;
}

#table-main-small {
    text-align: center;
}

#mobile-style {
    font-size: 13px;
    /* padding: 0px 5px 0 5px; */
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    text-align: left;
    line-height: 20px;
    /* vertical-align: middle; */
    /* line-height: 15px; */
    border: none;
    color: #000000;
    font-weight: 450;
    /* font-family: Arial, Helvetica, sans-serif; */
}

@media only screen and (max-width: 1000px) {
    #table-stats {
        width: 100%
    }

    #table-main {
        display: none
    }
}

#downloadable {
    border-collapse: collapse;
    border-spacing: 0; /* Set spacing to zero */
}

#downloadable tr,
#downloadable th,
#downloadable td {
    border: 1px solid black;
    padding-left: 8px;
    padding-right: 8px;
    color: #000000;
}

#list-table {
    border-collapse: collapse;
    border-spacing: 0;
    /* font-size: 18px; */
}

#list-table tr,
#list-table th, 
#list-table td {
    border: 1px solid black;
    /* padding-left: 8px; */
    /* padding-right: 8px; */
    color: #000000;
}
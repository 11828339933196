@font-face {
    font-family: 'jameelnoori';
    src: url('../../Sass/venders/font.ttf') format('truetype')
}

#fonting {
    font-family: 'jameelnoori', sans-serif
}

.fonting {
    font-family: 'jameelnoori', sans-serif
}
.table-container {
	
	max-width: 100%;
	margin: 0 auto 0;
	// padding-left: 1rem;
	position:relative;
	overflow-y:auto;
	&__table {
	  height: min-content;
	  width: 100%;
	  border-collapse: collapse;
	  border: 1.2rem;
	  tbody{
		tr {
			&:nth-child(odd) {

				background-color: $bg-color;
		}
		  }
	  }
	  thead {
  
		tr {
			
			background-color: $color-primary-light ;
		  th{
			color:$color-white;
			// font-weight: 900;
			
		  }
		}
	  }
  
	  td, th {
		border: none;
		padding: .2rem ;
		padding-left: .5rem;
		font-size: 1.3rem;
	
		@include respond(phone){
			font-size: 1.2rem;

		}
		text-align: left;
	  }
  
  
	  &--break-md {
		position:relative;
		@include respond(tab-port){
			tbody{
				tr {
					padding:1rem;
				  }
			  }
			thead {
				display: none;
			  }
			  tr {
				
				display: block;
				margin-bottom: 5px;
				
			  }
			
			  td {
				display: block;
				position: relative;
				padding-left: 130px;
				text-align: left;
				border-bottom: 0;
			
				&:last-child {
				  border-bottom: 1px solid $border-color;
				}
			
				&::before {
				  content: attr(data-heading);
				  position: absolute;
				  top: .5rem;
				  left: 0;
				  width: max-content;
				  height: 100%;
				  display: flex;
				  align-items: left;
				  color: $color-grey-dark-3;
				  font-size: 1.6rem;
				  text-transform: capitalize;
				  @include respond(phone){
					  
					font-size: 1.3rem;
				}
				  padding: 0 .2rem;
				  justify-content: left;
				}
			  }
		}
	  }
  
	//   &--break-sm {
  
	// 	@media (max-width: 575px) {
	// 	  @include break;
	// 	}
	//   }
	}
  }

  


 .UrduFontTableHead{
	th {
		font-family: UrduFont !important;
		font-size: 1.6rem !important;
		// @include respond(phone){
		// 	font-size: 1.3rem !important;

		// }
	  }
 } 